import React, { useState, useEffect } from "react";
import { Fab, IconButton, Input } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Tooltip } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';


import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  Table,
  Avatar,
  Radio,
  Box,
  TextField,
  RadioGroup,
  FormControl,
  Typography,
  InputLabel,
  Collapse,
  MenuItem,
  Container,
  Select,
  styled,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {env} from '../../../env.js'
import { H2, H1 } from "app/components/Typography";
import { padding } from "@mui/system";

const UserSettings = () => {

  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const variable = env.REACT_APP_BASE_URL;

  const [tenantuuid, setTenantUuid] = useState('');
  // const [firstName, setFirstname] = useState('');
  // const [lastName, setLastname] = useState('');

  // const [tenantuuid, setTenantUuid] = useState('');
  // const [firstName, setFirstname] = useState('');
  // const [lastName, setLastname] = useState('');


  const getFirstLetter = (name) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };

  const email_id = localStorage.getItem('email_id');
  const tenant_uuid = localStorage.getItem('tenantuuid');


  const [fullName, setFullName] = useState('');

  useEffect(() => {
    const firstName = localStorage.getItem('first_name');
    const lastName = localStorage.getItem('last_name');
    setFullName(`${firstName} ${lastName}`);
  }, []);



  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleUpdateTenant = async () => {
    const [firstName, lastName] = fullName.trim().split(/\s+/);
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      const body = JSON.stringify({
        tenant_uuid: tenantuuid,
        first_name: firstName,
        last_name: lastName,
        mobile_number: "",
        date_of_birth: "",
        address: "",
        username: '',
        password: "",
        name: '',
        status: 'Logged Out',
        domain_name: 'ncwnfjewew',
        role_permission: 'cnknkj',
        edit_tenant: 'dcxcdsf',
        account_balance: '0',
        max_tenant_limit: ""
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: body,
        redirect: 'follow'
      };

      const response = await fetch(`${variable}/update_tenant`, requestOptions);
      const result = await response.json();

      if (result.Status === '1') {
        toast(result.Msg, { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
        localStorage.setItem(tenant_uuid, tenantuuid);
        localStorage.setItem('first_name', firstName);
        localStorage.setItem('last_name', lastName);
      } else {
        toast(result.Msg, { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
        console.error('Error updating tenant details');
      }
    } catch (error) {
      console.error('Error updating tenant details', error);
      toast('An error occurred while updating details', { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
    }
  };




  return (
    <div
      style={{
        margin: "20px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
      }}
    >
      <Box
        className="box1"
        style={{
          width: "100%",
          padding: "0px 25px",
        }}
      >
        <p
          style={{
            fontSize: "25px",
            fontWeight: "520",
          }}
        >
          Your Settings
        </p>
        <table
          style={{
            padding: "2px 0px",
          }}
        ></table>

        <H2
          style={{
            fontSize: "20px",
          }}
        >
          Your Profile
        </H2>
        <hr />

        <Table
          style={{
            width: "50%",
            padding: "1px 0px",
          }}
        >
          <tr>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Avatar
                sx={{
                  cursor: "pointer",
                  color: "#2D5A71",
                  height: "100px",
                  width: "100px",
                  fontSize: "36px",
                  fontWeight: "600",
                }}
                style={{
                  margin: "10px",
                }}
              >
                {getFirstLetter(fullName)}
                {/* {getFirstLetter(fullName)} */}
              </Avatar>

            </div>
          </tr>
        </Table>
        <div
          style={{
            padding: "30px 0px",
          }}
        >
          {/* <TextField
            inputProps={{ readOnly: true }}
            style={{
              width: "30%",
              height: "8%",
            }}
            type="text"
            label="Name"
            value={`${first_name} ${last_name}`}
            // onChange={(e) => handleInputChange()}
          
          /> */}

          <TextField
            style={{ width: "30%", height: "5%" }}
            type="text"
            label="Full Name"
            value={fullName}
            onChange={handleFullNameChange}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateTenant}
            style={{ margin: '10px', width: '10%', height: '6%' }}
          >
            Update
          </Button>

          <table
            style={{
              padding: "12px 0px",
            }}
          >
            <tr>

              <td
                style={{
                  padding: "12px 25px ",
                }}
              >
              </td>
            </tr>

          </table>

          <>
            <H2
              style={{
                fontSize: "20px",
              }}
            >
              Authorization
            </H2>
            <hr />
            <p
              style={{
                fontSize: "18px",
              }}
            >
              Email ID -
              <span
                style={{
                  marginLeft: "5px",
                  color: "#2D5A71",
                }}
              >
                {email_id}
              </span>
            </p>
          </>
        </div>
      </Box>


    </div>
  );
};

export default UserSettings;
