import { lazy } from 'react';
import Loadable from 'app/components/Loadable';
import UserSettings from '../sessions/UserSettings';

const AppTable = Loadable(lazy(() => import('./tables/AppTable')));
const AppForm = Loadable(lazy(() => import('./forms/AppForm')));
const AppButton = Loadable(lazy(() => import('./buttons/AppButton')));
const AppIcon = Loadable(lazy(() => import('./icons/AppIcon')));
const AppProgress = Loadable(lazy(() => import('./AppProgress')));
const AppMenu = Loadable(lazy(() => import('./menu/AppMenu')));
const AppCheckbox = Loadable(lazy(() => import('./checkbox/AppCheckbox')));
const AppSwitch = Loadable(lazy(() => import('./switch/AppSwitch')));
const AppRadio = Loadable(lazy(() => import('./radio/AppRadio')));
const AppSlider = Loadable(lazy(() => import('./slider/AppSlider')));
const AppDialog = Loadable(lazy(() => import('./dialog/AppDialog')));
const AppSnackbar = Loadable(lazy(() => import('./snackbar/AppSnackbar')));
const AppAutoComplete = Loadable(lazy(() => import('./auto-complete/AppAutoComplete')));
const AppExpansionPanel = Loadable(lazy(() => import('./expansion-panel/AppExpansionPanel')));
const CreateTenant = Loadable(lazy(() => import('../Tenant/CreateTenant')));
const EditTenant = Loadable(lazy(() => import('../Tenant/EditTenant')));
const TenantUser = Loadable(lazy(() => import('../Tenant/TenantUser')));
const User = Loadable(lazy(() => import('../Users/User')));
const AddUser = Loadable(lazy(() => import('../Users/AddedUser')));
const CreateUser = Loadable(lazy(() => import('../Users/UserProfile')));
const EditUser = Loadable(lazy(() => import('../Users/EditUsers')));


const materialRoutes = [
  { path: '/material/table', element: <AppTable /> },
  { path: '/material/form', element: <AppForm /> },
  { path: '/material/buttons', element: <AppButton /> },
  // { path: '/tenants/add-Tenant', element: <AddTenant /> },
  { path: '/tenants/add-Tenant', element: <CreateTenant /> },
  { path: '/tenant/edit-tenant', element: <EditTenant /> },
  { path: 'tenant/tenant-user', element: <TenantUser /> },
  { path: '/user/Users', element: <User /> },
  { path: '/adduser/addusers', element: <AddUser /> },
  { path: '/user/add-user', element: <CreateUser /> },
  { path: '/user/edit-user', element: <EditUser /> },
  { path: '/material/icons', element: <AppIcon /> },
  { path: '/material/progress', element: <AppProgress /> },
  { path: '/material/menu', element: <AppMenu /> },
  { path: '/material/checkbox', element: <AppCheckbox /> },
  { path: '/material/switch', element: <AppSwitch /> },
  { path: '/material/radio', element: <AppRadio /> },
  { path: '/material/slider', element: <AppSlider /> },
  { path: '/material/autocomplete', element: <AppAutoComplete /> },
  { path: '/material/expansion-panel', element: <AppExpansionPanel /> },
  { path: '/material/dialog', element: <AppDialog /> },
  { path: '/material/snackbar', element: <AppSnackbar /> },
  { path: '/sessions/UserSettings', element: <UserSettings /> },
];

export default materialRoutes;
